<template>
    <div>404: Page not Found</div>
</template>

<script>
export default {
    name: '404Page',
}
</script>

<style scoped></style>
